<template>
  <div class="study">
    <div class="w_1200">
      <div style="width: 429px">
        <img src="~assets/img/knowledge/tit.png" alt="" />
        <p>
          <span>1.</span>
          <i>每个库的内容为您精心挑选，用心整理。</i>
        </p>
        <p>
          <span>2.</span>
          <i>海量知识点，按照理法方药证病案穴等归类整理，多而不乱。</i>
        </p>
        <p>
          <span>3.</span>
          <i>每个知识点，精心为你分类、标签，快速定位，方便学习。</i>
        </p>
      </div>
      <div style="width: 771px; position: relative">
        <img src="~assets/img/knowledge/juhe-phone1.png" alt="" />
        <img
          src="~assets/img/knowledge/juhe-phone2.png"
          alt=""
          class="phone2"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Study'
}
</script>

<style scoped>
.study {
  background: url(~assets/img/knowledge/conbg2.jpg) top center no-repeat;
  background-size: 100% 100%;
  /* height: 849px; */
  padding-top: 110px;
  min-width: 1220px;
}

.w_1200 {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.w_1200 div:nth-of-type(1) img {
  margin-top: 170px;
  padding-bottom: 20px;
  float: left;
  /* object-fit: none; */
}

.w_1200 div:nth-of-type(1) p {
  font-size: 24px;
  line-height: 40px;
  margin-top: 15px;
  overflow: hidden;
  display: flex;
  float: left;
}

.w_1200 div:nth-of-type(1) p span {
  /* display: inline-block; */
  width: 40px;
  text-align: left;
}

.w_1200 div:nth-of-type(1) p i {
  font-style: normal;
  /* display: inline-block; */
  width: 389px;
  text-align: left;
}

.phone2 {
  position: absolute;
  top: 314px;
  left: 544px;
  width: 228px;
  height: 521px;
}
</style>

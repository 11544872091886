<template>
  <div class="knowledge">
    <!-- <service-guide /> -->
    <study />
    <fast-find />
  </div>
</template>

<script>
// import ServiceGuide from './subpage/serviceGuide'
import Study from './subpage/study'
import FastFind from './subpage/fastFind'

export default {
  name: 'Knowledge',
  components: {
    // ServiceGuide,
    Study,
    FastFind
  }
}
</script>

<style scoped>
.knowledge {
  text-align: center;
  width: 100%;
  min-width: 1220px;
}
</style>
